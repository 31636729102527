import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import uminek_ooo01 from '../images/uminek.ooo_01.jpg'



const PV = () => (
  <Layout>
    <h1>自己紹介動画を投稿し、活動を開始しました！</h1>

<a href="https://www.youtube.com/watch?v=yKdSWuYvbaQ" target="_blank" rel="noopener noreferrer"><img src={uminek_ooo01} alt="uminek.ooo_movie01"/></a>
<br />
<a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=yKdSWuYvbaQ">【自己紹介】Vセーラー uminek.ooo/うみねこ です！ よろしくね</a>
<br />
<br />
<a target="_blank" rel="noopener noreferrer" href="https://www.nicovideo.jp/watch/sm34393715">【ニコニコ動画】【自己紹介】Vセーラー uminek.ooo/うみねこ です！ よろしくね</a>

<br />
<p><br />PVからかなり経ちましたが、失踪したわけではありません！</p>
<p>エンディングに凝ってみたり、モデルの髪を VRoidStudio でリメイクしてたりしたら <br />
気づいたらこんなにお待たせしてました…</p>

<p><br />本日! ついに、1 本目の動画をアップロードしました! ﾔｯﾀｰ</p>
<p>自己紹介や、今後やりたいことを簡単に語ってみました。<br />
セーリングはいいぞ！</p>

<p>ではまた！</p>

    <Link to="/">トップへ戻る</Link>
  </Layout>
)

export default PV
